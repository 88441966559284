import './index.scss';
import { Suspense, lazy } from "react";
import { createRoot } from 'react-dom/client';
import { Slogan } from '../src/components/icons/logo/Slogan.js'

const App = lazy(() => import('./App.js'));
// const Slogan = lazy(() => import('../src/components/icons/logo/Slogan.js'));

const root = createRoot( document.getElementById('root') );

root.render(
  <Suspense delayMs={0} fallback={ <div className="splash-infinite" style={{ alignItems:'center', display:'flex', height:'100vh', justifyContent:'center', width:'100%' }}><Slogan height={3.5} width={7.25} title='Hárgomo' /></div> }>
    <App Logo={Slogan} />
  </Suspense>
);